import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import CustomRoute from "./CustomRoute";
import { UNAUTHENTICATED_ROUTES } from "./constant";

const Landing = React.lazy(() => import("../containers/Landing"));
const Freebie = React.lazy(() => import("../containers/Freebie"));
const SuccessResult = React.lazy(() => import("../containers/SuccessResult"));
const VerifyEmail = React.lazy(() => import("../containers/AuthPages/VerifyEmail"));
const ResetPassword = React.lazy(() => import("../containers/AuthPages/ResetPassword"));

const Routes = () => {
    return (
        <Switch>
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.LANDING}
                component={Landing}
                title="Landing"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.FREEBIE}
                component={Freebie}
                title="Freebie"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.EVENT}
                component={Freebie}
                title="Event"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_FIND}
                component={Freebie}
                title="View Find"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_HOME}
                component={Freebie}
                title="View Home"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_NAVIGATOR}
                component={Freebie}
                title="View Navigator"
            />

            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_HOTLINES}
                component={Freebie}
                title="View Hotlines"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_CRIME}
                component={Freebie}
                title="View Crime"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_FREEBIES}
                component={Freebie}
                title="View Freebies"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_CONNECT}
                component={Freebie}
                title="View Connect"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_ACCESS}
                component={Freebie}
                title="View Access"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_EXPLORE}
                component={Freebie}
                title="View Explore"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_CHAT}
                component={Freebie}
                title="View Chat"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_EVENTS}
                component={Freebie}
                title="View Events"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_TOP_FIVE}
                component={Freebie}
                title="View Top5"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_ACCOUNT}
                component={Freebie}
                title="View Account"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_PUSH_MANAGEMENT}
                component={Freebie}
                title="View Push Management"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.SHARED_BUTTON}
                component={Freebie}
                title="Shared Button"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_TOOTRIS}
                component={Freebie}
                title="View Tootris"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VIEW_SCHOOL_A_HOOP}
                component={Freebie}
                title="View School A Hoop"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.RESET_PASSWORD}
                component={ResetPassword}
                title="Reset Password"
            />
            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.VERIFY_EMAIL}
                component={VerifyEmail}
                title="Verify Email"
            />

            <CustomRoute
                exact
                path={UNAUTHENTICATED_ROUTES.SUCCESS}
                component={SuccessResult}
                title="Success"
            />

            <Route exact path="*" component={Landing}>
                <Redirect to={UNAUTHENTICATED_ROUTES.LANDING} />
            </Route>
        </Switch>
    );
};

export default Routes;
