export const UNAUTHENTICATED_ROUTES = {
    RESET_PASSWORD: "/reset",
    VERIFY_EMAIL: "/verify",
    SUCCESS: "/success",
    LANDING: "/landing",
    FREEBIE: "/freebie/:id",
    EVENT: "/event/:id",
    VIEW_FIND: "/view-find",
    VIEW_HOME: "/view-home",
    VIEW_NAVIGATOR: "/view-navigator",
    VIEW_HOTLINES: "/view-hotlines",
    VIEW_CRIME: "/view-crime",
    VIEW_FREEBIES: "/view-freebies",
    VIEW_CONNECT: "/view-connect",
    VIEW_ACCESS: "/view-access",
    VIEW_EXPLORE: "/view-explore",
    VIEW_CHAT: "/view-chat",
    VIEW_EVENTS: "/view-events",
    VIEW_TOP_FIVE: "/view-top5",
    VIEW_ACCOUNT: "/view-account",
    VIEW_PUSH_MANAGEMENT: "/view-push-management",
    SHARED_BUTTON: "/share-button",
    VIEW_FIND: "/view-find",
    VIEW_EDUCATIONAL_SUPPORT: "/view-educational-support",
    VIEW_TOOTRIS: "/view-tootris",
    VIEW_SCHOOL_A_HOOP: "/view-school-a-hoop"
};

export const AUTHENTICATED_ROUTES = {
    DASHBOARD: "/dashboard"
};
