const paginationConstant = {
    PAGE_SIZE: 10
};

const pageProperties = {
    WIDTH: 1024,
    OVERFLOW_Y: 250
};

export const STORAGE_CONST = {
    TOKEN: "TOKEN",
    THEME_TYPE: "theme_type"
};

export { paginationConstant, pageProperties };

export const APP_NAME = "THE BEST PLACE FOR KIDS";
export const APP_NAME_MINI = "Parent Pass";

export const FOOTER_TEXT_APP_NAME =
    "Copyright 2022 - The Best Place for Kids!®. In collaboration with Unique Software Development.";

export const THEME = {
    LITE: "light",
    DARK: "dark"
};

export const REGISTRATION_TYPE = {
    EMAIL: 1,
    PHONE: 2
};

