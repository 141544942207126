import "assets/vendors/style";
import 'react-spring-bottom-sheet/dist/style.css'

import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Provider } from "react-redux";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import "assets/vendors/style";
import configureStore, { history } from "./appRedux/store";
import withClearCache from "./ClearCache";
import FullPageLoader from "./components/FullPageLoader";
import Routes from "./routes";
import LocalStorageService from "./util/local-storage.service";
import AuthService from "./util/auth.service";
import { STORAGE_CONST, THEME } from "./constants/constant";

const store = configureStore();

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

const isTokenExist = AuthService.isTokenExist();
const localSaveTheme = LocalStorageService.get(STORAGE_CONST.THEME_TYPE);
const currentTheme = localSaveTheme && isTokenExist ? localSaveTheme : THEME.LITE;



const App = () => (
  <React.Suspense fallback={<FullPageLoader />}>
    <React.StrictMode>
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={currentTheme}
        insertionPoint="styles-insertion-point"
      >
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </Provider>
      </ThemeSwitcherProvider>
    </React.StrictMode>
  </React.Suspense>
);

const ClearCacheComponent = withClearCache(App);

const NextApp = () => {
  return <ClearCacheComponent />;
};

export default NextApp;
